import SplitType from 'split-type'
import { disableWfScroll, mm, sel, selAll, stResizeObserver, vh } from './utils'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { lenis } from './lenis'

export default function home2() {
  // mm.add('(min-width: 768px)', () => {
  gsap.registerPlugin(ScrollTrigger)

  const navbarW$ = sel('.navbar-a')
  const navbar$ = sel('#navbar')
  const navbarHeight = navbar$.offsetHeight

  function navbarTheme(theme, el, transparent = false, offset = 0) {
    // if (scroll < 200) return
    let fg,
      bg,
      newFg,
      newBg = ''
    let bgInit,
      newBgInit = {}
    if (theme === 'light') {
      fg = 'white'
      bg = 'black'
      newFg = 'black'
      newBg = 'white'
    } else if (theme === 'dark') {
      fg = 'black'
      bg = 'white'
      newFg = 'white'
      newBg = 'black'
    }
    if (transparent) {
      bg = 'transparent'
      bgInit = { '--color--theme-init-bg': 'transparent' }
      newBgInit = { '--color--theme-init-bg': 'var(--color--theme-bg)' }
    }
    const navbarTl = gsap.fromTo(
      navbar$,
      { '--color--theme-fg': fg, '--color--theme-bg': bg, ...bgInit },
      { '--color--theme-fg': newFg, '--color--theme-bg': newBg, ...newBgInit, paused: true, immediateRender: false }
    )
    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=' + navbarHeight,
      onEnter({ progress, direction, getVelocity }) {
        gsap.to(navbarTl, { duration: 0.5, progress: 1, ease: 'power1.out' })
      },
      onLeaveBack({ progress, direction, getVelocity }) {
        gsap.to(navbarTl, { duration: 0.5, progress: 0, ease: 'power1.out' })
      },
      // onToggle({ progress, direction, getVelocity }) {
      //   // only on entering the section (from top)
      //   if (progress > 0.2) return
      //
      //   // to reverse the easing
      //   gsap.to(navbarTl, { duration: 0.5, progress: direction === 1 ? 1 : 0, ease: 'power1.out' })
      // },
    })
  }
  // console.log(lenis.scroll, vh(100))
  //
  // if (lenis.scroll > vh(100)) {
  //   console.log('scroll')
  //
  //   gsap.set(navbar$, { '--color--theme-init-bg': '--color--theme-bg' })
  // }

  const hero2$ = sel('.hero2')
  const heroP$ = sel('.hero2__p')
  const heroBtnW$ = sel('.hero2__btn-w .btn:not(.is--blur)')
  const btnBlur$ = sel('.btn.is--blur')
  const heroBg = sel('.hero__bg-w')

  const heroHsT = new SplitType('.hero2__h', { types: 'words, chars' })

  gsap
    .timeline()
    .from(heroBg, { scale: 1.1, ease: 'power1.out', duration: 3 })
    .from(
      heroHsT.chars,
      {
        opacity: 0,
        filter: 'blur(50px)',
        stagger: {
          amount: 0.5,
          // from: 'end',
        },
        duration: 0.5,
        ease: 'none',
        // ease: 'power1.out',
      },
      0.5
    )
    .from(heroP$, { opacity: 0, ease: 'power1.out', duration: 1 }, '>-0.5')
    .from(heroBtnW$, { autoAlpha: 0, ease: 'power1.out', duration: 1 }, '>-0.5')
    .fromTo(btnBlur$, { backdropFilter: 'blur(0px)', autoAlpha: 0 }, { autoAlpha: 1, backdropFilter: 'blur(10px)', duration: 1 }, '<+0.1')
  // .from(navbar$, { autoAlpha: 0, ease: 'power1.out', duration: 1 }, '>-0.75')

  const quoteSec$ = sel('.quote-sec')
  const quotePsT = new SplitType('.quote__p', { types: 'words' })
  // navbarTheme('light', quoteSec$, true)

  ScrollTrigger.create({
    trigger: quoteSec$,
    start: 'top bottom=+1',
    end: 'top top',
    scrub: true,
    pin: hero2$,
    pinSpacing: false,
  })

  // gsap.set(quotePsT.words, { color: '#ddd' })
  // gsap.to(quotePsT.words, {
  //   color: 'black',
  //   stagger: 0.1,
  //   // scrollTrigger: {
  //   //   trigger: quoteSec$,
  //   //   // start: 'top center',
  //   //   // end: 'bottom 75%',
  //   //   start: 'top top',
  //   //   end: 'bottom top',
  //   //   pin: true,
  //   //   scrub: true,
  //   // },
  //   scrollTrigger: {
  //     trigger: quoteSec$,
  //     start: 'top 50%',
  //     end: 'bottom 70%',
  //     scrub: true,
  //   },
  // })

  const serviceSec$ = sel('.home-service-sec')
  const serviceLo$ = sel('.home-service-lo')
  const serviceH$ = sel('.home-service__title')
  const serviceItem$a = selAll('.home-service__item')
  const serviceCta$ = sel('.home-service__cta')
  const serviceCtaH$ = sel('.home-service__cta h2')
  const serviceCtaP$ = sel('.home-service__cta__p')
  const serviceCtaBtn$ = sel('.home-service__cta__btn-w')
  // navbarTheme('dark', serviceSec$)

  gsap.set(serviceLo$, { y: '-100vh' })
  ScrollTrigger.create({
    trigger: serviceSec$,
    start: 'top bottom',
    // end: 'top top',
    // end: 'top+=' + vh(50) + ' top',
    end: '+=' + vh(100),
    scrub: true,
    pin: serviceLo$,
    pinSpacing: false,
    // animation: gsap.from(serviceLo$, { opacity: 0, ease: 'none' }),
  })
  ScrollTrigger.create({
    trigger: serviceSec$,
    start: 'top center',
    end: 'top+=' + vh(20) + ' top',
    scrub: true,
    animation: gsap.from([serviceH$, ...serviceItem$a], { opacity: 0, y: 20, ease: 'power1.out', stagger: 0.3 }),
  })

  // ScrollTrigger.create({
  //   trigger: serviceCta$,
  //   start: 'top 50%',
  //   end: 'top top',
  //   scrub: true,
  //   animation: gsap.from([serviceCtaH$, serviceCtaP$, serviceCtaBtn$], { opacity: 0, y: 40, ease: 'none', stagger: 0.3 }),
  // })

  const testSec$ = sel('.test-sec')
  const testLo$ = sel('.test__lo')
  const testItem$a = selAll('.test__item')
  let testItemRight$a = []
  const testLoOffset = testLo$.offsetLeft
  testItem$a.forEach((el, i) => {
    const elOffset = el.offsetLeft
    console.log(testLoOffset - elOffset)

    if (testLoOffset - elOffset < 0) {
      testItemRight$a.push(el)
    }
  })
  // console.log(testItemRight$a)

  // navbarTheme('light', testSec$)
  gsap.set(testItemRight$a, { y: -100 })

  // gsap.set(testSec$, { backgroundColor: 'black' })
  // ScrollTrigger.create({
  //   trigger: testSec$,
  //   start: 'top 80%',
  //   end: '+=' + vh(75),
  //   scrub: true,
  //   animation: gsap
  //     .timeline({ defaults: { duration: 1, ease: 'none' } })
  //     .to([serviceSec$, testSec$], { backgroundColor: 'white', color: 'black' }, 0)
  //     .fromTo(navbar$, { '--color--theme-bg': 'black', '--color--theme-fg': 'white' }, { '--color--theme-bg': 'white', '--color--theme-fg': 'black' }, 0),
  // })

  ScrollTrigger.create({
    trigger: testSec$,
    start: 'top bottom',
    endTrigger: testLo$,
    end: 'bottom top',
    scrub: true,
    animation: gsap.to(testItemRight$a, { y: -400, ease: 'power1.in' }),
  })

  // const testQuoteW$ = sel('.test__quote-w')
  // const testQuote$ = sel('.test__quote')
  // gsap.set(testQuote$, { y: '-60vh', opacity: 0 })
  // ScrollTrigger.create({
  //   trigger: testLo$,
  //   start: 'bottom 60%',
  //   endTrigger: testQuoteW$,
  //   end: 'bottom bottom',
  //   pin: testQuote$,
  //   pinSpacing: false,
  //   scrub: true,
  //   animation: gsap.to(testQuote$, { opacity: 1, ease: 'none' }),
  // })

  const footer$ = sel('.footer2')
  const footerCtaW$ = sel('.footer2__cta__info-w')
  const footerCta$ = sel('.footer2__cta__info')
  const footerBg$ = sel('.footer2__cta__bg')
  // navbarTheme('dark', footer$)

  /*
  gsap.set(footerCta$, { y: '-100vh' })
  ScrollTrigger.create({
    trigger: footerCtaW$,
    start: 'top bottom',
    end: 'top top',
    pin: footerCta$,
    pinSpacing: false,
    scrub: true,
    // animation: gsap.to(footerCta$, { y: 0, ease: 'none' }),
  })

  gsap.set(footerBg$, { y: -vh(80) })
  ScrollTrigger.create({
    trigger: footer$,
    start: 'top bottom',
    end: 'bottom bottom',
    scrub: 0,
    animation: gsap.to(footerBg$, { y: vh(30), ease: 'none' }),
  })
    */
  //
  stResizeObserver()
  // })
}
