import { addSplideClasses, connectSplideArrows, sel, selAll, splideAutoWidth } from './utils'
import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'

export default function Membership() {
  // passing click() to google drive video iframe doesn't work
  function videoInit() {
    const video$ = sel('#membership-vid iframe')
    if (!video$) return
    const videoImg$ = sel('.for-employers__img__img')
    videoImg$.style.display = 'block'
    videoImg$.addEventListener('click', () => {
      videoImg$.style.display = 'none'
      video$.click()
      console.log('clicked', video$)
    })
  }
  // videoInit()

  function galleryInit() {
    const classPrefix = 'membership-media'
    if (!sel(`.${classPrefix}__slider`)) return

    addSplideClasses(classPrefix + '__slider')
    const slider = new Splide(sel(`.${classPrefix}__slider`), {
      arrows: false,
      pagination: false,
      gap: '2.5rem',
      type: 'loop',
      perPage: 2,
      autoScroll: { speed: 0.6, autoStart: false },
      breakpoints: {
        767: {
          perPage: 1,
        },
      },
    })

    // splideAutoWidth(slider)
    slider.mount({ AutoScroll })
    // slider.mount()
  }
  galleryInit()

  const zoraVid$ = sel('.zora-video__vid')
  setTimeout(() => {
    // const src = zoraVid$.dataset.src
    // console.log('src', src)
    // zoraVid$.src = src
    // zoraVid$.load()
    // zoraVid$.play()
    zoraVid$.style.display = 'block'
  }, 1000)
}
