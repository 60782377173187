import gsap from 'gsap'
import { selAll } from './utils'

export default function Exec() {
  const prop$a = selAll('.leadership__item')
  prop$a.forEach((prop) => {
    const text$ = prop.querySelector('.leadership__item__p-w')
    const link$ = prop.querySelector('.leadership__item__a')
    const heightOpen = text$.getBoundingClientRect().height
    text$.classList.add('is--closed')
    const heightClosed = text$.getBoundingClientRect().height
    gsap.set(text$, { height: heightClosed })

    link$.onclick = () => {
      if (text$.classList.contains('is--closed')) open(text$, link$, heightOpen)
      else close(text$, link$, heightClosed)
    }
  })
  function open(text$, link$, height) {
    text$.classList.remove('is--closed')
    gsap.to(text$, { height: height, duration: 0.5 })
    link$.textContent = 'Read Less'
  }
  function close(text$, link$, height) {
    text$.classList.add('is--closed')
    gsap.to(text$, { height: height, duration: 0.5 })
    link$.textContent = 'Read More'
  }
}
