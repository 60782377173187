import gsap from 'gsap'
import { pauseVideos, sel, selAll } from './utils'
import 'vidstack/player/styles/default/theme.css'
import 'vidstack/player/styles/default/layouts/video.css'
// import 'vidstack/player/styles/base.css'
// import 'vidstack/player/styles/plyr/theme.css'
import { VidstackPlayer, VidstackPlayerLayout, PlyrLayout } from 'vidstack/global/player'

export default async function EventPeter() {
  // console.log('2')
  // pauseVideos()

  const player = await VidstackPlayer.create({
    target: '#customVideo',
    // title: '',
    src: 'youtube/4UgnVc7kRxE',
    // src: 'vimeo/1061484821',
    poster: 'https://cdn.prod.website-files.com/655b9a852f3d2a5de3c90048/67ec2aaf454cc0ab8e781425_hero_q85.webp',
    crossOrigin: true,
    playsInline: true,
    load: 'idle',
    // load: 'eager',
    // layout: new PlyrLayout(),
    layout: new VidstackPlayerLayout(),
    // layout: new VidstackPlayerLayout({
    //   // thumbnails: 'https://files.vidstack.io/sprite-fight/thumbnails.vtt',
    // }),
  })

  const playerW$ = sel('.join__img__img-w')
  const playerBg$ = playerW$.querySelector('video')
  const sec$ = sel('.join-sec')
  const close$ = sec$.querySelector('.mod__x-w')
  const modW$ = sec$.querySelector('.mod-w')
  const mod$ = sec$.querySelector('.mod')
  const bg$ = sec$.querySelector('.mod-bg')
  const playerB$ = sec$.querySelector('.vds-blocker')
  // gsap.set(modW$, { autoAlpha: 0, display: 'none' })

  // videostack layout needs display block
  gsap.set([modW$, playerB$], { pointerEvents: 'none', opacity: 0 })

  let firstPlay = true
  const tl = gsap
    .timeline({ defaults: { duration: 1, ease: 'power4.inOut' }, paused: true })
    .set(modW$, { pointerEvents: 'none' })
    .to(modW$, { opacity: 1 })
    // .fromTo(modW$, { autoAlpha: 0, display: 'none' }, { autoAlpha: 1, display: 'block' })
    .from(mod$, { y: 50 }, 0)
    .to(
      {},
      {
        onComplete: () => {
          // player.play()
        },
        duration: 0.01,
      }
    )
    .set(modW$, { pointerEvents: 'auto' })

  playerW$.onclick = () => {
    // console.log(player)
    playerBg$.pause()
    tl.play()
    if (firstPlay) {
      // console.log('firstPlay')
      firstPlay = false
      player.addEventListener(
        'can-play',
        (event) => {
          // console.log('canplay')
          player.play()
        },
        { once: true }
      )
    }
    player.play()
  }
  ;[close$, bg$].forEach((el) => {
    el.onclick = (e) => {
      // Stop both bubbling up and capturing down
      e.stopImmediatePropagation()
      // e.stopPropagation()
      // console.log('close')
      playerBg$.play()
      player.pause()
      tl.reverse()
    }
  })
  // player.play()
}
