import { lenis } from './lenis'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { mm, sel, selAll } from './utils'
import gsap from 'gsap'

export default function blog2() {
  // fs toc offset doesn't work with webflow.js scroll + lenis
  lenis.destroy()
  mm.add('(max-width: 768px)', () => {
    gsap.registerPlugin(ScrollTrigger)

    const sideCol$ = sel('.blog__side-col')
    const tocTitle$ = sel('.blog__toc__h-w')
    const tocW$ = sel('.blog__toc-w')
    const toc$ = sel('.blog__toc')
    const tocToc$ = sel('.blog__toc__toc')
    const rt$ = sel('.blog__rt:not(.hide')
    const tocArrow$ = sel('.blog__toc__svg')
    const tocA$a = selAll('.fs-toc_link-wrapper')
    const pin$ = sel('.pin-spacer')

    tocTitle$.setAttribute('data-close', '-1')
    const tocHeight = tocToc$.offsetHeight

    setTimeout(() => {
      ScrollTrigger.create({
        trigger: sideCol$,
        start: 'top top+=64',
        endTrigger: rt$,
        end: 'bottom top+=128',
        // end: '+=5000',
        pin: tocW$,
        pinSpacing: false,
      })
      ScrollTrigger.create({
        trigger: sideCol$,
        start: 'top top+=64',
        endTrigger: rt$,
        end: 'top top+=128',
        scrub: true,
        animation: gsap.to(tocToc$, {
          height: 0,
          ease: 'none',
          onComplete: () => {
            tocTitle$.setAttribute('data-close', '1')
          },
          onReverseComplete: () => {
            tocTitle$.setAttribute('data-close', '-1')
          },
        }),
      })
      const tocTlOpts = { ease: 'power4.out', duration: 0.5 }
      ;[tocTitle$].forEach((el) => {
        el.addEventListener('click', () => {
          toggleToc()
        })
      })
      document.addEventListener('scroll', (event) => {
        if (tocTitle$.getAttribute('data-close') === '0') {
          closeToc()
        }
      })
      function toggleToc() {
        if (tocTitle$.getAttribute('data-close') === '0') {
          closeToc()
        } else if (tocTitle$.getAttribute('data-close') === '1') {
          openToc()
        }
      }

      function openToc() {
        gsap.to(tocToc$, { height: 'auto', ...tocTlOpts })
        gsap.to(tocArrow$, { rotate: 180, ...tocTlOpts })
        tocTitle$.setAttribute('data-close', '0')
      }
      function closeToc() {
        gsap.to(tocToc$, { height: 0, ...tocTlOpts })
        gsap.to(tocArrow$, { rotate: 0, ...tocTlOpts })
        tocTitle$.setAttribute('data-close', '1')
      }
    }, 500)
  })
}
