import Splide from '@splidejs/splide'
import '@splidejs/splide/css'

import { addSplideClasses, addStaticSplideClasses, connectSplideArrows, sel } from './utils'
import gsap from 'gsap'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'

export default function Lp() {
  // Hero video
  const heroVid$ = sel('.is--home-hero')
  const heroBg$ = sel('.hero__bg-w')
  gsap.to(heroVid$, {
    y: '75%',
    opacity: 0,
    ease: 'none',
    scrollTrigger: {
      trigger: heroBg$,
      start: 'top top',
      end: 'bottom top',
      scrub: 0,
      // markers: true,
    },
  })

  function insideInit() {
    const slider = sel('.lp-inside__slider')
    addStaticSplideClasses(slider)
    const splide = new Splide(slider, {
      type: 'fade',
      autoplay: true,
      interval: 5000,
      // pauseOnHover: false,
      arrows: false,
      pagination: false,
      // drag: false,
      // keyboard: false,
      speed: 1000,
      rewind: true,
    })
    splide.mount()
    connectSplideArrows(splide, '.lp-inside__slider__nav')
    const numCurrent$ = sel('.lp-inside__slider__num-current')
    const numTotal$ = sel('.lp-inside__slider__num-total')
    splide.on('moved', () => {
      numCurrent$.textContent = `0${splide.index + 1}`
    })
    numCurrent$.textContent = `0${splide.index + 1}`
    numTotal$.textContent = '0' + splide.length
  }
  insideInit()
  function locationInit() {
    const slider = sel('.visiting-location__slider')
    addSplideClasses(slider)
    const splide = new Splide(slider, {
      perPage: 2,
      gap: '1.5rem',
      rewind: true,
      pagination: false,
      arrows: false,
      // autoplay: true,
      type: 'loop',
      // speed: speed,
      // easing: ease,
      // interval: speed * 2,
      breakpoints: {
        991: {
          perPage: 2,
          perMove: 2,
          gap: '1rem',
        },
        768: {
          perPage: 1,
          perMove: 1,
          // speed: speed / 2,
        },
      },
    }).mount({ AutoScroll })
  }
  locationInit()

  const lifeP$ = sel('.lp-life__accordion__p')
  lifeP$.classList.add('is--closed')
  const lifeBtn$ = sel('.lp-life__accordion__a')
  const lifeBtnText$ = sel('.accordion__a__p')
  lifeBtn$.onclick = () => {
    // lifeP$.style.webkitLineClamp = 'unset'
    if (lifeP$.classList.contains('is--closed')) {
      lifeBtnText$.textContent = 'Show Less'
    } else {
      lifeBtnText$.textContent = 'Show More'
    }
    lifeP$.classList.toggle('is--closed')
  }

  const overviewList$ = sel('.lp-overview__list')
  overviewList$.classList.add('is--closed')
  const overviewBtn$ = sel('#overview-btn')
  const overviewBtnText$ = overviewBtn$.querySelector('.accordion__a__p')
  overviewBtn$.onclick = () => {
    if (overviewList$.classList.contains('is--closed')) {
      overviewBtnText$.textContent = '+ View Less Diagnostics'
    } else {
      overviewBtnText$.textContent = '+ View More Diagnostics'
    }
    overviewList$.classList.toggle('is--closed')
    /*
      grid-auto-rows: 0; 
  grid-template-rows: 2;
  */
  }
}
