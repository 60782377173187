import TextPlugin from 'gsap/TextPlugin'
import { mm, sel, selAll } from './utils'
import gsap from 'gsap'

gsap.registerPlugin(TextPlugin)

export default function Schedule() {
  const preloader$ = sel('.preloader')
  if (!preloader$) return
  const preloaderNum$ = sel('.preloader__num')
  const preloaderSvg$ = sel('.preloader__svg')

  preloader$.classList.add('show')
  const preloaderDuration = preloader$.dataset.duration
  const svgTl = gsap
    .timeline()
    .from([preloader$, preloaderNum$, preloaderSvg$], { autoAlpha: 0, duration: 1, ease: 'power4.out' })
    .to(preloaderSvg$, { repeat: -1, repeatDelay: 0, rotate: 360, duration: 1, ease: 'sine.out' }, 0)
  gsap.to(preloaderNum$, {
    innerText: 100,
    snap: { innerText: 1 },
    duration: preloaderDuration,
    ease: 'power4.inOut',
    onComplete: () => {
      svgTl.kill()
      gsap
        .timeline()
        .to(preloaderNum$, { scale: 0.5, duration: 1, ease: 'power4.out' })
        .to(
          preloader$,
          {
            duration: 1,
            autoAlpha: 0,
            onComplete: () => {
              preloader$.remove()
            },
          },
          0
        )
    },
  })

}
