import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { addSplideClasses, connectSplideArrows, connectSplideBullets, devMode, initObserver, onDomReady, sel, selAll } from './utils'
import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import { Intersection } from '@splidejs/splide-extension-intersection'

gsap.registerPlugin(ScrollTrigger)
export default function Home() {
  // Hero video
  const heroVid$ = sel('.is--home-hero')
  const heroBg$ = sel('.hero__bg-w')
  gsap.to(heroVid$, {
    y: '75%',
    opacity: 0,
    ease: 'none',
    scrollTrigger: {
      trigger: heroBg$,
      start: 'top top',
      end: 'bottom top',
      scrub: 0,
      // markers: true,
    },
  })
  // Diagnostics sec
  const diagnostics$ = sel('.diagnostics__lo')
  const diagnosticsImg$ = sel('.diagnostics__img')
  const diagnosticsImgBg$ = sel('.diagnostics__vid__bg-img')
  const diagnosticsImgFg$ = sel('.diagnostics__vid__fg-img')
  const diagnosticsDim$ = sel('.diagnostics__vid__dim')
  const diagnosticsVidW$ = sel('.diagnostics__vid-w')
  const diagnosticsInfo$a = selAll('.diagnostics__info')
  // diagnosticsImgBg$.style.opacity = '0'
  const diagnosticsTl = gsap
    .timeline({ defaults: { ease: 'power4.out', duration: 1 }, repeat: -1, repeatDelay: 2 })
    .to(diagnosticsImg$, { opacity: 0, duration: 2 })
    .to(diagnosticsDim$, { opacity: 0, duration: 2 }, 0)

  diagnosticsInfo$a.forEach((el, i) => {
    const currentTitle$ = el.querySelector('.diagnostics__percent-w')
    const currentText$ = el.querySelector('.diagnostics__p')
    const previousI = i > 0 ? i - 1 : diagnosticsInfo$a.length - 1
    const previousEl$ = diagnosticsInfo$a[previousI]
    const previousTitle$ = previousEl$.querySelector('.diagnostics__percent-w')
    const previousText$ = previousEl$.querySelector('.diagnostics__p')
    const yShift = 20
    gsap.set(currentTitle$, { opacity: 0, y: yShift })
    gsap.set(currentText$, { opacity: 0, y: yShift * 2 })

    if (i === 0) {
      diagnosticsTl.to(currentTitle$, { opacity: 1, y: 0, duration: 2 }, '>-1')
    } else {
      diagnosticsTl
        //
        .to(previousTitle$, { opacity: 0, y: -yShift, ease: 'power4.in', duration: 2 })
        .to(previousText$, { opacity: 0, y: -yShift, ease: 'power4.in', duration: 2 }, '>-1.8')
        .to(currentTitle$, { opacity: 1, y: 0, duration: 2 })
    }
    diagnosticsTl.to(currentText$, { opacity: 1, y: 0, duration: 2 }, '>-1.9')
  })
  diagnosticsTl
    //
    .to(diagnosticsDim$, { opacity: 1, duration: 2 })
    .to(diagnosticsImg$, { opacity: 1, duration: 2 }, '<')
  // .from(diagnosticsImgBg$, { opacity: 0, duration: 1 }, '<')

  ScrollTrigger.create({
    animation: diagnosticsTl,
    trigger: diagnostics$,
    start: 'top 50%',
  })
  // Titles
  /*
  devMode(1)
  const title$a = selAll('.title')
  gsap.fromTo(
    [...timeLineItem$a],
    { clipPath: 'inset(0px 100% 0px 0px round 0 0.5rem 0.5rem 0)' },
    {
      clipPath: 'inset(0px 0% 0px 0px round 0 0.5rem 0.5rem 0)',
      duration: 2,
      ease: 'expo.out',
      stagger: { each: 0.2 },
      scrollTrigger: { trigger: '.timeline__list', start: 'top 75%' },
    }
  )*/
}
