setTimeout(() => {
  // document.querySelectorAll('video').forEach((video) => video.pause())
}, 1000)

import './style.styl'
import { sel, selAll, onDomReady, addSplideClasses, connectSplideArrows, connectSplideBullets, vh } from './utils'
import { lenis } from './lenis'
import Home from './home'
import Home2 from './home2'
import Event from './event'
import Zora from './zora'
import EventPeter from './event-peter'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Lenis from '@studio-freight/lenis'
import Location from './location'
import Thank from './thank'
import Lp from './lp'
import Splide from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import OldHome from './old-home'
import Exec from './exec'
import Membership from './membership'
import Blog2 from './blog2'
import Schedule from './schedule'
import SplitType from 'split-type'

lenis.on('scroll', ScrollTrigger.update)
gsap.ticker.add((time) => {
  lenis.raf(time * 1000)
})
gsap.ticker.lagSmoothing(0)

const _dataPage = sel('.page-wrapper') || sel('body')
const dataPage = _dataPage?.getAttribute('data-page')
// sticky navbar
const navbar$ = sel('#navbar')
if (navbar$) {
  if (!navbar$.classList.contains('is--0220')) {
    navbar$.style.position = 'fixed'
    if (!navbar$.classList.contains('is--1210')) {
      const navbarTl = gsap.fromTo(
        navbar$,
        // { backgroundColor: 'rgba(27,27,27, 0)' },
        { backgroundColor: 'rgba(16, 20, 25, 0)' },
        {
          backgroundColor: 'rgba(16, 20, 25, 1)',
          // backdropFilter: 'blur(10px)',
          ease: 'linear',
          paused: true,
        }
      )

      ScrollTrigger.create({
        trigger: 'body',
        start: vh(10) + 'top',
        onToggle({ direction, getVelocity }) {
          // to reverse the easing
          gsap.to(navbarTl, { duration: 1.5, progress: direction === 1 ? 1 : 0, ease: 'expo.out' })
        },
      })
    } else if (dataPage === 'home2') {
      // gsap.set(navbar$, { '--color--theme-bg': 'white' })
      const navbarTl = gsap.fromTo(
        navbar$,
        {
          borderBottomColor: 'color-mix(in srgb, var(--color--theme-fg), transparent 100%)',
          // backgroundColor: 'color-mix(in srgb, var(--color--theme-init-bg), transparent 100%)',
          // backgroundColor: 'color-mix(in srgb, var(--color--theme-bg), transparent 100%)',
        },
        {
          borderBottomColor: 'color-mix(in srgb, var(--color--theme-fg), transparent 90%)',
          // backgroundColor: 'color-mix(in srgb, var(--color--theme-init-bg), transparent 0%)',
          ease: 'linear',
          paused: true,
        }
      )
      ScrollTrigger.create({
        trigger: 'body',
        start: vh(60) + 'top',
        onToggle({ direction, getVelocity }) {
          // to reverse the easing
          gsap.to(navbarTl, { duration: 1.5, progress: direction === 1 ? 1 : 0, ease: 'expo.out' })
        },
      })
    }
  }
}

switch (dataPage) {
  case 'home':
    Home()
    break
  case 'home2':
    Home2()
    break
  case 'membership':
    Membership()
    break
  case 'location':
    Location()
    break
  case 'thank':
    Thank()
    break
  case 'lp':
    Lp()
    break
  case 'old-home':
    OldHome()
    break
  case 'exec':
    Exec()
    break
  case 'blog2':
    Blog2()
    break
  case 'schedule':
    Schedule()
    break
  case 'zora':
    Zora()
    break
  case 'event-peter':
    EventPeter()
    break
  case 'event':
    Event()
    break
  case 'error':
    Error()
    break
  default:
    console.log('unknown data-page:', dataPage)
}

// Service cards
const overviewList$ = sel('.lp-overview__list')
if (overviewList$) {
  overviewList$.classList.add('is--closed')
  const overviewBtn$ = sel('#overview-btn')
  // if (!overviewBtn$) return console.error('overviewBtn$ not found')

  const overviewBtnText$ = overviewBtn$.querySelector('.accordion__a__p')
  overviewBtn$.onclick = () => {
    if (overviewList$.classList.contains('is--closed')) {
      overviewBtnText$.textContent = '+ View Less Diagnostics'
    } else {
      overviewBtnText$.textContent = '+ View More Diagnostics'
    }
    overviewList$.classList.toggle('is--closed')
  }
}

// Faq chat link
const chatLink$ = document.querySelector('#faq-chat')

if (chatLink$) {
  chatLink$.onclick = () => {
    LO.messenger.open()
    console.log('chat clicked')
  }
}

// FAQ Accordion
onDomReady(() => {
  const faqDd = selAll('.faq__accordion')
  const shiftX = -1000

  faqDd.forEach((faq, i) => {
    const faqTitle$ = faq.querySelector('.faq__question-w')
    const faqDdIco$ = faq.querySelector('.faq__question__ico-w')
    const faqP$ = faq.querySelector('.faq__answer__p')
    const faqNav$a = faq.querySelectorAll('.faq__answer__li')
    const faqBg$ = faq.querySelector('.faq__bg-w')

    // console.log(faq)
    const faqLi$a = faq.querySelectorAll('.faq__answer__li-w')
    faqLi$a.forEach((faqLi, i) => {
      const nav = faqLi.querySelector('.faq__answer__li')
      const modalW$ = faqLi.querySelector('.faq__item__mod-w')
      const modalClose$ = faqLi.querySelector('.faq__item__x-w')
      const modalCloseBg$ = faqLi.querySelector('.faq__item__mod-bg')
      const faqTl = gsap.timeline({
        defaults: {
          duration: 1.2,
          ease: 'none',
        },
        paused: true,
      })

      faqTl
        .to(faqDdIco$, { opacity: 0, duration: 0.3 }, 0)
        .to([faqTitle$, faqP$, faqNav$a], { x: shiftX, opacity: 0 }, 0)
        .to(faqBg$, { x: shiftX / 2 }, 0)
        .set(modalW$, { display: 'block' }, 0)
        .fromTo(modalW$, { autoAlpha: 0 }, { autoAlpha: 1 }, 0)
        .from(modalW$, { x: 200 }, 0)

      nav.onclick = () => {
        //   faqTl.play()
        gsap.to(faqTl, { time: faqTl.duration(), duration: faqTl.duration(), ease: 'power4.out' })
      }
      ;[modalClose$, modalCloseBg$].forEach((el) => {
        el.onclick = () => {
          // faqTl.reverse()
          gsap.to(faqTl, { time: 0, duration: faqTl.duration(), ease: 'power4.out', overwrite: true })
          // gsap.set(modalW$, { display: 'none' }, 0)
        }
      })
    })
  })
})

// remove play buttons in the power saving mode
const vids$a = selAll('video')

vids$a.forEach((video) => {
  const promise = video.play()
  promise
    ?.catch(() => {
      const img$ = video.style.backgroundImage
      const parent$ = video.parentElement
      video.style.display = 'none'
      parent$.style.backgroundImage = img$
      parent$.style.backgroundPosition = 'center'
      parent$.style.backgroundSize = 'cover'
    })
    .then(() => {
      video.play()
    })
})

function storyMainSplide() {
  const prefix = 'stories'
  const el = '.' + prefix + '__slider'
  if (!sel(el)) return
  addSplideClasses(el)

  const slider = new Splide(el, {
    arrows: false,
    pagination: false,
    type: 'fade',
    gap: '0px',
    perPage: 1,
    // loop: true,
    rewind: true,
    speed: 1500,
    autoplay: 'true',
    // easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
  }).mount()
  connectSplideArrows(slider, prefix + '__arrows', '.round-arrow')
  connectSplideBullets(slider, prefix + '__bullets')
}
storyMainSplide()

function storyMoreSplide() {
  const prefix = 'stories-more'
  const el = sel('.' + prefix + '__slider')
  if (!el) return
  const list$ = el.querySelector('.w-dyn-items')
  const item$a = list$.querySelectorAll('.w-dyn-item')
  let div = document.createElement('div')
  div.classList.add('stories__mod-w')
  item$a.forEach((item, i) => {
    const modal$ = item.querySelector('.mod-w')
    if (!modal$) return
    // modal.querySelector('.mod__info').setAttribute('data-modal', '')
    const modalX$ = modal$.querySelector('.mod__x-w')
    const modalBg$ = modal$.querySelector('.mod-bg')
    // console.log(modalX$, modalBg$)

    div.appendChild(modal$)
    const modalTl = gsap.timeline({
      defaults: {
        duration: 1.2,
        ease: 'none',
      },
      paused: true,
    })
    modalTl.set(modal$, { display: 'block' }, 0).fromTo(modal$, { autoAlpha: 0, x: '5rem' }, { autoAlpha: 1, x: '0' }, 0)

    item.onclick = () => {
      console.log('clicked', i)
      gsap.to(modalTl, { time: modalTl.duration(), duration: modalTl.duration(), ease: 'power4.out' })
    }
    ;[modalX$, modalBg$].forEach((el) => {
      el.onclick = () => {
        gsap.to(modalTl, { time: 0, duration: modalTl.duration(), ease: 'power4.out', overwrite: true })
      }
    })
  })

  const storiesSec = sel('.stories-sec') || sel('.old-stories-sec')
  storiesSec.appendChild(div)

  addSplideClasses(el)

  const slider = new Splide(el, {
    arrows: false,
    pagination: false,
    gap: '1.25rem',
    perPage: 2,
    type: 'loop',
    autoplay: 'pause',
    /* intersection: {
        inView: {
          autoplay: true,
        },
        outView: {
          autoplay: false,
        },
      },*/
    speed: 1500,
    easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
    autoScroll: { speed: 1, autoStart: true },
    breakpoints: {
      768: {
        perPage: 1,
      },
    },
  }).mount({ AutoScroll })
}
storyMoreSplide()

// Plans services
const plan$ = sel('#plan-mob')
if (plan$) {
  const serviceA$ = selAll('.membership__service-w')
  serviceA$.forEach((service) => {
    const toggle$ = service.querySelector('.imaging-dropdown-toggle')
    const plusIco$ = service.querySelector('.dropdown-plus-icon')
    const minusIco$ = service.querySelector('.dropdown-minus-icon')
    const list$ = service.querySelector('.membership__service__list')
    list$.classList.add('toggle--closed')
    toggle$.onclick = () => {
      list$.classList.toggle('toggle--closed')
      const closed = list$.classList.contains('toggle--closed')

      plusIco$.style.display = closed ? 'block' : 'none'
      minusIco$.style.display = closed ? 'none' : 'block'
    }
  })
}

// Append ga userid to /signup links
// console.log('signup links')
const signup$a = Array.from(document.querySelectorAll('a[href], button[href]')).filter((el) => {
  try {
    // Resolve the href relative to the current document
    const url = new URL(el.getAttribute('href'), window.location.href)
    // Check that the URL is from the current domain and its pathname ends with "/signup"
    // console.log('signup link', url.origin)
    const path = url.pathname
    const rightPath = path.endsWith('/signup') || path.endsWith('/signup/executive') || path.endsWith('/contact-us')
    return url.origin === window.location.origin && rightPath
  } catch (e) {
    // In case of an invalid URL
    return false
  }
})
gtag('get', 'G-EWGTWHF6QQ', 'client_id', function (clientId) {
  // console.log(clientId)
  signup$a.forEach((el) => {
    // console.log('signup link', el)
    try {
      // Create a URL object relative to the current page.
      const url = new URL(el.getAttribute('href'), window.location.href)
      // Append (or update) the search parameter "data" to "value".
      url.searchParams.set('clientid', clientId)
      // Set the updated URL back as the element's href.
      el.setAttribute('href', url.href)
    } catch (error) {
      console.error('Failed to update URL for element:', el, error)
    }
  })
})

// standard header component
const headerComp$ = sel('#standard-hero')
if (headerComp$) {
  const compP$ = sel('.comp-hero__p')
  const compBtnW$ = headerComp$.querySelector('.btn:not(.is--blur)')
  const compBtnBlur$ = headerComp$.querySelector('.btn.is--blur')
  const compHeroBg$ = sel('.hero__bg-w')
  const compSubtitle$ = sel('.comp-hero__subtitle')
  const compHsT = new SplitType('.comp-hero__h', { types: 'words, chars' })

  gsap
    .timeline()
    .from(compHeroBg$, { scale: 1.1, ease: 'power1.out', duration: 3 })
    .from(
      compHsT.chars,
      {
        opacity: 0,
        filter: 'blur(50px)',
        stagger: {
          amount: 0.5,
          // from: 'end',
        },
        duration: 0.5,
        ease: 'none',
        // ease: 'power1.out',
      },
      0.5
    )
    .from(compP$, { opacity: 0, ease: 'power1.out', duration: 1 }, '>-0.5')
    .from(compBtnW$, { autoAlpha: 0, ease: 'power1.out', duration: 1 }, '>-0.5')
    .fromTo(compBtnBlur$, { backdropFilter: 'blur(0px)', autoAlpha: 0 }, { autoAlpha: 1, backdropFilter: 'blur(10px)', duration: 1 }, '<+0.1')
    .from(compSubtitle$, { autoAlpha: 0, ease: 'power1.out', duration: 1 }, '>-0.5')
  // .from(navbar$, { autoAlpha: 0, ease: 'power1.out', duration: 1 }, '>-0.75')
}
