import { addSplideClasses, connectSplideArrows, sel, selAll } from './utils'
import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import { Intersection } from '@splidejs/splide-extension-intersection'
import { mapStyle } from './mapStyle'
import { Loader } from '@googlemaps/js-api-loader'

export default function Location() {
  console.log('Thank you page')

  function heroInit() {
    const name = 'thank-you'
    addSplideClasses(name + '__slider')
    const splide = new Splide('.' + name + '__slider', {
      // perPage: 1,
      // width: '100%',
      autoplay: false,
      // autoHeight: true,
      type: 'fade',
      rewind: true,
      pagination: false,
      arrows: false,
    })

    addSplideClasses(name + '__slider-thumb')
    const thumbnails = new Splide('.' + name + '__slider-thumb', {
      gap: '1rem',
      perPage: 4,
      rewind: true,
      pagination: false,
      arrows: false,
      isNavigation: true,
      speed: 1500,
      easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
      breakpoints: {
        /*

        991: {
          // perPage: 4,
          // gap: '1rem',
        },
        768: {
          perPage: 3,
        },
                        */

        478: {
          // perPage: 3,
          gap: '0.75rem',
        },
      },
    })
    splide.sync(thumbnails)
    splide.mount()
    thumbnails.mount()
    connectSplideArrows(splide, 'arrows', '.round-arrow')
  }
  heroInit()
}
