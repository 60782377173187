import gsap from 'gsap'
import { sel, selAll } from './utils'

export default function Zora() {
  // console.log('Zora')
  const heroPhoneW$ = sel('.zora-hero__phone-w')
  const heroTl = gsap.timeline()
  heroTl.from(heroPhoneW$, { yPercent: 10, opacity: 0, duration: 3, ease: 'power4.out', delay: 1 })

  const playerW$ = sel('.about-zora__vid-w:not(.hide)')
  const sec$ = sel('.about-zora-sec')
  const close$ = sec$.querySelector('.mod__x-w')
  const modW$ = sec$.querySelector('.mod-w')
  const mod$ = sec$.querySelector('.mod')
  const bg$ = sec$.querySelector('.mod-bg')
  gsap.set(modW$, { autoAlpha: 0, display: 'none' })
  // const player$ = sel('#plyr')
  const tl = gsap
    .timeline({ defaults: { duration: 1, ease: 'power4.inOut' }, paused: true })
    // , onStart: () => (modW$.style.display = 'block')
    .fromTo(modW$, { autoAlpha: 0, display: 'none' }, { autoAlpha: 1, display: 'block' })
    .from(modW$, { y: 50 }, 0)

  playerW$.onclick = () => {
    // console.log(player)
    tl.play()
    player.on('statechange', (event) => {
      // const instance = event.detail.plyr
    })
    setTimeout(() => {
      player.play()
    }, 2000)
  }
  ;[close$, bg$].forEach((el) => {
    el.onclick = () => {
      player.pause()
      tl.reverse()
    }
  })
  // player.play()
}
