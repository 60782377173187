import gsap from 'gsap'
import { sel, selAll } from './utils'

export default function Event() {
  const date$ = sel('.even-intro__mod__h') // e.g. March 19, 2025, 1:30-3:00pm

  const day$ = sel('#event-day')
  const hr$ = sel('#event-hr')
  const min$ = sel('#event-min')
  const sec$ = sel('#event-sec')

  // console.log('t', _date$, day$, hrs$, min$, sec$)

  const date = new Date(date$.textContent.split('-')[0])
  // console.log('date', date)

  const countDownDate = date.getTime()

  const x = setInterval(() => {
    // Get today's date and time
    const now = new Date().getTime()

    // Find the distance between now and the count down date
    const distance = countDownDate - now

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)

    // Display the result in the elements with id="demo"
    day$.textContent = days
    hr$.textContent = hours
    min$.textContent = minutes
    sec$.textContent = seconds

    // If the count down is over, write some text
    if (distance < 0) {
      clearInterval(x)
      day$.textContent = 'EXPIRED'
      hr$.textContent = ''
      min$.textContent = ''
      sec$.textContent = ''
    }
  }, 1000)
}
